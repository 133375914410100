.customer_care_main_component {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
}

.customer_care_header {
  width: 100%;
  padding: 20px;
  display: flex;
}

.customer_care_back_icon {
  width: 10px;
  cursor: pointer;
  margin-right: 10px;
  padding: 10px 0px 10px 0px;
}

.customer_care_header_title {
  font-weight: 400;
  font-size: 16px;
  color: var(--color-white);
}

.customer_care_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
  overflow: hidden;
}

.customer_care_form_group {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid var(--color-coral);
  border-radius: 8px;
  position: relative;
}

.customer_care_input_label {
  position: absolute;
  top: -12px;
  left: 10px;
  background-color: #232528;
  color: #82838E;
  font-weight: 400;
  font-size: 12px;
  padding: 0 5px;
}

.customer_care_input {
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  color: #EAEAEC;
  background-color: transparent;
  padding-left: 5px;
  outline: none;
}

.customer_care_input_label_bottom {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #1d2026;
  color: #82838E;
  font-weight: 400;
  font-size: 12px;
  padding: 0 5px;
}

.customer_care_textarea {
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 16px;
  color: #EAEAEC;
  background-color: transparent;
  padding-left: 5px;
  outline: none;
}

.customer_care_btn_component {
  width: 100%;
  margin-top: auto;
}

.customer_care_btn {
  width: 100%;
  padding: 7px 0;
  color: var(--color-white);
  background-color: var(--color-silver);
  border: none;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
}