.all_rewards_main_component {
  max-width: 500px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.all_rewards_main_screen {
  width: 100%;
  height: calc(100% - 59px);
  display: flex;
  flex-direction: column;
}

.all_rewards_header {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: flex-end;
  background-image: url('../../assets/images/reward-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px;
}

.all_rewards_header_coin_row {
  width: 100%;
  height: fit-content;
  display: flex;
}

.all_rewards_header_coins {
  width: fit-content;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  margin-right: 10px;
  color: white;
  background: var(--color-purple);
  border: 1px solid #82838E;
  border-radius: 50px;
  font-weight: 600;
}

.diamond_icon {
  width: 20px;
  margin-top: 3px;
  margin-right: 5px;
}
.diamond_icon_card {
  width: 20px;
  /* margin-top: 3px; */
  margin-right: 5px;
}
.diamond_icon_rewardpopup {
  width: 20px;
  /* margin-top: 3px; */
  /* margin-right: 5px; */
}

.all_rewards_header_coin_plus_icon {
  margin: auto 0;
}

.plus_icon {
  width: 24px;
  cursor: pointer;
}

.all_rewards_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 20px;
}

.all_rewards_tabs_component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(100% 12053.03% at 100% 50%, rgba(47, 48, 67, 0) 0%, #2F3043 47.9%, rgba(47, 48, 67, 0) 100%);
  filter: drop-shadow(0px 2px 20px rgba(47, 48, 67, 0.05));
}

.all_rewards_tab {
  font-weight: 400;
  color: #FFFFFF;
  font-size: 14px;
  margin: 0 10px;
  padding: 7px 0;
  cursor: pointer;
}

.all_rewards_active_tab {
  font-weight: 700;
  position: relative;
}

.all_rewards_active_tab::after {
  width: 100%;
  height: 2px;
  content: "";
  background: var(--color-yellow);
  border-radius: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.all_rewards_filter_component {
  width: 100%;
  display: flex;
  padding: 20px;
  overflow: auto;
}

.all_rewards_filter_component::-webkit-scrollbar {
  display: none;
}

.all_rewards_filter_type {
  padding: 3px 16px 10px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid var(--color-light);
  color: var(--color-light);
  cursor: pointer;
}

.all_rewards_filter_type_active {
  color: var(--color-purple);
  background: var(--color-yellow);
  border-radius: 18px;
  border: 1px solid var(--color-yellow);
}

.all_rewards_filtered_data_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 20px 60px;
  overflow: auto;
}

.all_rewards_filtered_data_scrollable_component::-webkit-scrollbar {
  display: none;
}

.all_rewards_filtered_data_component {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.all_rewards_reward_box {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: flex-end;
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.all_rewards_reward_data {
  position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 14px;
    padding: 12px 16px;
    background-image: url(../../assets/images/reward-product-bg.png);
    background-size: cover;
}

/* Get Reward Popup Starts */
.get_reward_popup_main_component {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 112;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: #111111; */
  /* opacity: 0.7; */
}

.get_reward_popup {
  width: 100%;
  /* height: 90%; */
  background-color: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}

.get_reward_popup_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: transparent;
}

.get_reward_popup_cancel_icon {
  width: 32px;
  cursor: pointer;
}

.get_reward_popup_body {
  width: 100%;
  /* height: 90%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
}

.get_reward_popup_body_scrollable_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: var(--primary); */
  overflow: auto;
}

.get_reward_popup_body_scrollable_component::-webkit-scrollbar {
  display: none;
}

.get_reward_popup_bg_component {
  width: 100%;
  height: 235px;
  display: flex;
  align-items: flex-end;
  background-image: url('../../assets/images/coupon-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 14px 14px 0 0;
}

.get_reward_popup_reward_details_component {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  padding-bottom: 0;
  background-color: rgba(59, 60, 74, 0.6);
  opacity: 0.95;
  backdrop-filter: blur(12px);
  border-radius: 10px 10px 0px 0px;
  text-align: left;
}

.get_reward_popup_hr_divider {
  width: 75%;
  height: 2px;
  background: #595969;
  border-radius: 14px;
  margin: auto;
  margin-top: 25px;
}

.get_reward_popup_brand_img {
  width: 48px;
  border-radius: 8px;
  margin-right: 10px;
  height: 48px;
  object-fit: cover;
}

.get_reward_popup_brand_name {
  font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 1;
}

.get_reward_popup_details {
  font-weight: 400;
  font-size: 14px;
  color: #EAEAEC;
  line-height: 1;
  text-align: left;
}

.get_reward_popup_reward_price {
  font-weight: 400;
  font-size: 14px;
  color: #EAEAEC;
}

.get_reward_popup_btn_component {
  width: 100%;
  padding: 25px;
  background-color: rgba(59, 60, 74, 0.6);
  opacity: 0.95;
  backdrop-filter: blur(12px);
}

.get_reward_popup_btn {
  width: 100%;
  padding: 10px 0 12px 0;
  color: var(--color-white);
  background-color: var(--color-red);
  border: none;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
}


/* Get Reward Popup Ends */

/* Buy Gems Starts */
.buy_gems_main_component {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
}

.buy_gems_header {
  width: 100%;
  padding: 20px;
}

.buy_gems_back_icon {
  width: 10px;
  cursor: pointer;
}

.buy_gems_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

.buy_gems_title_component {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.buy_gems_title {
  font-weight: 700;
  font-size: 20px;
  color: var(--color-white);
}

.buy_gems_user_balance {
  font-weight: 700;
  font-size: 12px;
  color: var(--color-white);
  background-color: var(--color-purple);
  border: 1px solid #82838E;
  border-radius: 25px;
  padding: 5px 15px;
}

.buy_gems_offers_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow: auto;
}

.buy_gems_offers_component::-webkit-scrollbar {
  display: none;
}

.buy_gems_offer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  background: linear-gradient(106.35deg, rgba(47, 48, 67, 0.279) -36.4%, rgba(89, 89, 105, 0.9) 136.12%);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  box-shadow: inset 1px 0px 0px 1px rgb(159 102 147 / 90%);
}

.buy_gems_offer_gems {
  font-weight: 500;
  font-size: 20px;
  color: #EAEAEC;
}

.buy_gems_offer_price {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-white);
  background-color: var(--color-red);
  border-radius: 40px;
  padding: 5px 20px;
  border: none
}

.no_reward_component {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(100% 12053.03% at 100% 50%, rgba(47, 48, 67, 0) 0%, #2F3043 47.9%, rgba(47, 48, 67, 0) 100%);
  /* filter: drop-shadow(0px 2px 20px rgba(47, 48, 67, 0.05)); */
}

/* Buy Gems Ends */