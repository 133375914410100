.pg_status_main_component {
    width: 100%;
    max-width: 500px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
}


.pg_status_body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: hidden;
}

.pg_status_title_component {
    width: 100%;
    text-align: center;
    /* display: flex;
    justify-content: space-between; */
}

.pg_title {
    font-weight: 700;
    font-size: 20px;
    color: var(--color-white);
}
.pg_ps_status_component {
    width: 100%;
    border-radius: 26px;
    margin-top: 65px;
}

.pg_status_background_success {
    background-color: #36DE4C;
    text-align:  center;
    height:  100%;
    border-radius: inherit;
}
.pg_status_background_pending {
    background-color: #FF9544;
    text-align:  center;
    height:  100%;
    border-radius: inherit;
}
.pg_status_background_failed {
    background-color: #FF4646;
    text-align:  center;
    height:  100%;
    border-radius: inherit;
}
.pg_status_icon{
    margin-top: 42px;
    height: 100px;
    width: 100px;
}
.pg_status_title{
    margin-top: 34px; 
    color: #000;
    font-size: 36px
}
.pg_status_amount{
    margin-top: 25px;
    color: #000;
    font-size: 30px;
}
.pg_status_date{
    margin-top: 8px;
    color: #000;
    font-size: 14px;
}
.pg_status_transaction{
    margin-top: 16px;
    color: #000; 
    font-size: 14px;
}